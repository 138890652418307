.jekyll-linkpreview-wrapper {
  max-width: 600px;
  margin-bottom: 20px;
}

.jekyll-linkpreview-wrapper p {
  display: none;
}


.jekyll-linkpreview-wrapper-inner {
  border: 1px solid rgba(0,0,0,.1);
  padding: 12px;
}

.jekyll-linkpreview-content {
  position: relative;
  height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
}

.jekyll-linkpreview-image {
  position: absolute;
  top: 0;
  right: 0;
}

.jekyll-linkpreview-image img {
  width: 100px;
  height: 100px;
}

.jekyll-linkpreview-body {
  margin-right: 110px;
}

.jekyll-linkpreview-body-nog {
  margin-right: 10px;
}

.jekyll-linkpreview-title {
  font-size: 17px;
  margin: 0 0 2px;
  line-height: 1.3;
}

.jekyll-linkpreview-description {
  line-height: 1.5;
  font-size: 13px;
}

.jekyll-linkpreview-footer {
  font-size: 11px;
}


blockquote {
  border-left: #ccc 3px solid;
  padding-left: 1em;
  margin-left: 1em;
}



/*! div style */
.image-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
  justify-content: center;
  padding: 4px;
}

.image-gallery .box {
  flex-basis: 25%;
  width: 100%;
  padding: 10px;
  margin: 2px;
}

.img-gallery {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.social-media-list {
  list-style: none;
  margin-left: 0;
}

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #828282;
  //padding-right: 5px;
  vertical-align: text-top;
}